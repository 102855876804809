import React, { useState, useCallback, useRef } from 'react';
import { Button, Dropdown, DropdownButton, FormControl, InputGroup } from 'react-bootstrap';
import api from './api';
import EmojiPicker from 'emoji-picker-react';
import PropTypes from 'prop-types';
import GifPicker from './giphy/SearchBox';
import mimeTypes from 'mime-types';

const NewMessageForm = ({ conversationId, userId, onNewMessage, handleStartTyping, handleStopTyping }) => {
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [mimeType, setMimeType] = useState('');
  const [messageType, setMessageType] = useState('text');
  const [thumbnail, setThumbnail] = useState(null);
  const [pickerState, setPickerState] = useState(false);

  const messageInputRef = useRef(null);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    let mime = file.type;

    if (!mime) {
      mime = mimeTypes.lookup(file.name);
    }

    if (messageType === 'image' && !mime.startsWith('image/')) {
      setError('Please upload an image file');
      return;
    }

    if (messageType === 'video' && !mime.startsWith('video/')) {
      setError('Please upload a video file');
      return;
    }

    setMimeType(mime);
    setFileExtension(fileExtension);

    if (messageType === 'image') {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = 200; // Set thumbnail width
          canvas.height = (canvas.width / img.width) * img.height; // Maintain aspect ratio
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const thumbnail = canvas.toDataURL();
          setThumbnail(thumbnail);
          setFile(file);
          if (messageType !== 'video') {
            setFileUrl(URL.createObjectURL(file));
          }
          setError('');
        };
        img.onerror = (e) => {
          console.log('Error loading image:', e);
          console.log('Image src:', img.src);
          console.log('Image natural width:', img.naturalWidth);
          console.log('Image natural height:', img.naturalHeight);
          setError('There was an error loading the image.');
        };
      };
      reader.onerror = () => {
        setError('There was an error reading the file.');
      };
      reader.readAsDataURL(file);
    } else if (messageType === 'video') {
      setFile(file);
      setError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (text.trim() !== '' || file !== null) {
      const formData = new FormData();
      formData.append('conversationId', conversationId);
      formData.append('text', text.trim());
      formData.append('userId', userId.current);
      formData.append('messageType', messageType);
      formData.append('mimeType', mimeType);
      formData.append('fileExtension', fileExtension);

      if (file !== null) {
        formData.append('file', file);
      }

      if (thumbnail !== null)
      {
        formData.append('thumbnail', thumbnail);
      }

      try {
        const response = await api.post('/messages', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data); // handle response as needed
        if (response.status === 200) {
          onNewMessage(response.data);
          setText('');
          setFile(null);
          setThumbnail(null);
          setFileUrl('');
          setMimeType('');
          setFileExtension('');
          setError('');
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data.File);
        } else {
          console.error(error); // handle error as needed
        }
      }
    }
  };

  const handleMessageTypeChange = useCallback((type) => {
    setMessageType(type);
    if (type === 'text') {
      messageInputRef.current.type = 'text';
    } else if (type === 'file' || type === 'video') {
      messageInputRef.current.type = 'file';
    }
  }, []);

  const handleEmojiClick = (emoji) => {
    setText((prevText) => prevText + emoji.emoji);
  };

  const handleGifSubmit = async (thumbnailUrl, url, noteText) => {
    const formData = new FormData();
    formData.append('conversationId', conversationId);
    formData.append('text', noteText.trim());
    formData.append('userId', userId.current);
    formData.append('thumbnail', thumbnailUrl);
    formData.append('fileUrl', url);

    try {
      const response = await api.post('/messages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data); // handle response as needed
      if (response.status === 200) {
        onNewMessage(response.data);
        setText('');
        setFile(null);
        setThumbnail(null);
        setFileUrl('');
        setError('');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.File);
      } else {
        console.error(error); // handle error as needed
      }
    }
  };

  const toggleEmojiPicker = useCallback(() => {
    if (pickerState === 'emoji')
    {
      setPickerState(null);
    } else {
      setPickerState('emoji');
    }
  }, [pickerState]);

  const toggleGifPicker = useCallback(() => {
    if (pickerState === 'gif')
    {
      setPickerState(null);
    } else {
      setPickerState('gif');
    }
  }, [pickerState]);

  return (
    <form onSubmit={handleSubmit} style={{ padding: '0px 0px 0px 20px' }}>
      <InputGroup>
        <DropdownButton
          as={InputGroup.Prepend}
          variant="outline-light"
          id="message-type-btn"
          title={<i className={ messageType === 'text' ? 'far fa-comment' : (messageType === 'file' ? 'far fa-image' : 'fas fa-video') }></i>}
        >
          <Dropdown.Item onClick={() => handleMessageTypeChange('text')}>
            <i className="far fa-comment"></i> Text
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleMessageTypeChange('file')}>
            <i className="far fa-image"></i> Image
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleMessageTypeChange('video')}>
            <i className="fas fa-video"></i> Video
          </Dropdown.Item>
        </DropdownButton>
        <Button
          as={InputGroup.Prepend}
          variant="outline-light"
          onClick={() => toggleEmojiPicker()}>
          <i className="far fa-smile"></i>
        </Button>
        <Button
          as={InputGroup.Prepend}
          variant="outline-light"
          onClick={() => toggleGifPicker()}>
          GIF
        </Button>
        {messageType === 'text' ? (
          <FormControl
            ref={messageInputRef}
            type="text"
            placeholder="Type a message"
            value={text}
            onChange={handleTextChange}
            onFocus={handleStartTyping}
            onBlur={handleStopTyping}
            style={{ opacity: 0.8 }}
          />
        ) : (
          <FormControl
            ref={messageInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ opacity: 0.8 }}
          />
        )}
        <Button variant="primary" type="submit" disabled={text.trim() === '' && file === null}>
          <i className="far fa-paper-plane"></i> Send
        </Button>
      </InputGroup>
      <div style={{ display: 'flex', alignSelf: 'flex-start', zIndex: '9' }}>
        {pickerState === 'emoji' && <EmojiPicker onEmojiClick={handleEmojiClick} />}
        {pickerState === 'gif' && <GifPicker onSubmit={handleGifSubmit} />}
        {error !== '' && <div className="text-danger">{error}</div>}
      </div>
    </form>
  );
};

NewMessageForm.propTypes = {
  conversationId: PropTypes.number,
  onNewMessage: PropTypes.func.isRequired,
  userId: PropTypes.shape({
    current: PropTypes.number
  }).isRequired,
  handleStartTyping: PropTypes.func.isRequired,
  handleStopTyping: PropTypes.func.isRequired
};

export default NewMessageForm;
