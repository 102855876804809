import React, { useState, useEffect, useRef, useCallback } from 'react';
import Conversation from './Conversation';
import Start from './Start';
import './Chat.css';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Modal } from 'react-bootstrap';
import api from './api';
import ChatSpinner from './ChatSpinner';

function Chat() {
  const [otherUserId, setOtherUserId] = useState(null);
  const [otherUserName, setOtherUserName] = useState('');
  const [conversationId, setConversationId] = useState(null);
  const [isInConversation, setIsInConversation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [age, setAge] = useState('');
  const [sex, setSex] = useState('');
  const [location, setLocation] = useState('');
  const modalShown = useRef(false);
  const userId = useRef(null);
  const hubConnection = useRef(null);
  const hubConnectionStatus = useRef(null);
  const conversationGroupStatus = useRef(null);
  const conversationStatus = useRef(null);

  console.log('Chat component re-rendered');

  // get conversationId from query parameter
  const params = new URLSearchParams(window.location.search);
  const queryConversationId = params.get('conversationId');

  const hubUrl = '/chatHub';
  if (hubConnection.current == null) {
    hubConnection.current = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        ServerTimeout: 120000, // in milliseconds
      })
      .withAutomaticReconnect()
      .build();
    hubConnection.current.onclose(() => {
      console.log('Connection closed');
      hubConnectionStatus.current = null;
      startConnection();
    });
    hubConnection.current.onreconnected(async () => {
      console.log('Connection reconnected');
      let convId = conversationId;
      if (convId == null) {
        convId = parseInt(queryConversationId);
      }
      console.log('joining conversation group ' + convId);
      try {
        await hubConnection.current.invoke('JoinConversationGroup', convId);
        conversationGroupStatus.current = 'joined';
      } catch (error) {
        console.error(error);
      }
    });
  }

  const getCookie = (name) => {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i=0;i < ca.length;i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  };

  const getUserByToken = async (token) => {
    const response = await api.get(`/Users/userbytoken?token=${token}`);
    return response.data;
  };

  const handleJoinConversation = useCallback(async () => {
    if (conversationId !== null || isInConversation) {
      return;
    }
    if (queryConversationId) {
      const token = getCookie('chatToken');
      if (token) {
        const newConversationId = parseInt(queryConversationId);
        setConversationId(newConversationId);
        const user = await getUserByToken(token);
        userId.current = user.id;
        const response = await api.get(`/conversations/${newConversationId}`);
        const conversation = response.data;
        if (userId.current === conversation.userId)
        {
          const response = await api.get(`/users/${conversation.otherUserId}`);
          const otherUser = response.data;
          const otherName = `(${otherUser.age}/${otherUser.sex}/${otherUser.location})`;
          setOtherUserName(otherName);
          setIsInConversation(true);
          console.log('joining conversation group ' + newConversationId);
          try {
            await hubConnection.current.invoke('JoinConversationGroup', newConversationId);
            conversationGroupStatus.current = 'joined';
          } catch (error) {
            console.error(error);
          }
        } else if (userId.current === conversation.otherUserId)
        {
          const response = await api.get(`/users/${conversation.userId}`);
          const otherUser = response.data;
          const otherName = `(${otherUser.age}/${otherUser.sex}/${otherUser.location})`;
          setOtherUserName(otherName);
          setIsInConversation(true);
          console.log('joining conversation group ' + newConversationId);
          try {
            await hubConnection.current.invoke('JoinConversationGroup', newConversationId);
            conversationGroupStatus.current = 'joined';
          } catch (error) {
            console.error(error);
          }
        } else {
          window.location.search = '';
          setConversationId(null);
        }
      }
    }
  }, [queryConversationId, conversationId, isInConversation]);

  // set conversationId based on query parameter if it exists
  useEffect(() => {
    handleJoinConversation();
  }, [queryConversationId, isInConversation, handleJoinConversation]);

  const onStartConversation = async (otherUserId, userName, id) => {
    if (conversationStatus.current == null) {
      conversationStatus.current = 'started';
      // Set user as unavailable for chat
      await api.put(`/users/${userId.current}`, {
        id: userId.current,
        isAvailable: false,
        conversations: [],
        messages: []
      });
      setOtherUserId(otherUserId);
      setOtherUserName(userName);
      setConversationId(null);
      setConversationId(id);
      setLoading(false);
      handleCloseModal();
    }
  };

  hubConnection.current.on('StartConversation', (id, conversationUserId, userName1, conversationOtherUserId, userName2) => {
    if (conversationId === null)
    {
      if (userId.current === conversationUserId)
      {
        onStartConversation(conversationOtherUserId, userName2, id);
      } else if (userId.current === conversationOtherUserId)
      {
        onStartConversation(conversationUserId, userName1, id);
      }
    }
  });

  const handleStartConversation = (userData) => {
    // Do something with the user data, like start a conversation
    setOtherUserId(userData.otherUserId);
    setIsInConversation(true);
    setConversationId(null);
    const startConversation = () => {
      setConversationId(userData.conversationId);
      console.log('joining conversation group ' + userData.conversationId);
      hubConnection.current.invoke('JoinConversationGroup', userData.conversationId)
        .then(() => {
          conversationGroupStatus.current = 'joined';
        })
        .catch(error => {
          console.error(error);
        });
    };
    setTimeout(startConversation, 100);
    setOtherUserName(userData.otherUserName);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = async () => {
    setShowModal(false);
    if (conversationId !== null) {
      console.log('joining conversation group ' + conversationId);
      try {
        await hubConnection.current.invoke('JoinConversationGroup', conversationId);
        conversationGroupStatus.current = 'joined';
      } catch (error) {
        console.error(error);
      }
      await api.put(`/users/${userId.current}`, {
        id: userId.current,
        isAvailable: false,
        conversations: [],
        messages: []
      });
    }
  };

  const joinConversationGroup = useCallback(async () => {
    console.log('joining conversation group ' + conversationId);
    try {
      await hubConnection.current.invoke('JoinConversationGroup', conversationId);
      conversationGroupStatus.current = 'joined';
    } catch (error) {
      console.error(error);
    }
  }, [hubConnection, conversationId]);
  
  const leaveConversationGroup = useCallback(async () => {
    if (conversationId !== null) {
      console.log('leaving conversation group ' + conversationId);
      try {
        await hubConnection.current.invoke('LeaveConversationGroup', conversationId);
        conversationGroupStatus.current = null;
      } catch (error) {
        console.error(error);
      }
    }
  }, [hubConnection, conversationId]);  

  const startConnection = useCallback(() => {
    if (hubConnectionStatus.current === null)
    {
      hubConnectionStatus.current = 'starting';
    
      hubConnection.current.start().then(() => {
        hubConnectionStatus.current = 'started';
        if (conversationId !== null) {
          joinConversationGroup();
        }
      });
    } else if (hubConnectionStatus.current === 'started' && conversationGroupStatus.current === null)
    {
      if (conversationId !== null)
      {
        joinConversationGroup();
      }
    }
  }, [conversationId, hubConnection, joinConversationGroup]);

  const createNewConversation = () => {
    // Call API to get a random User object and create a new Conversation object
    conversationStatus.current = null;
    const maxRetries = 60; // maximum number of retries
    const retryInterval = 5000; // retry interval in milliseconds
    let otherUser = null;
    let convId = null;
    let retries = 0;
    setLoading(true);
    const getRandomUser = async () => {
      const randomUserResponse = await api.get(`/Users/${userId.current}/random`);
      otherUser = randomUserResponse.data;
    
      if (otherUser) {
        const createConversationResponse = await api.post('/conversations', {
          userId: userId.current,
          otherUserId: otherUser.id,
          users: [],
          messages: []
        });
        
        // Set user as unavailable for chat
        await api.put(`/users/${userId.current}`, {
          id: userId.current,
          isAvailable: false,
          conversations: [],
          messages: []
        });
        
        convId = createConversationResponse.data.id;
        const otherName = `(${otherUser.age}/${otherUser.sex}/${otherUser.location})`;
        
        handleStartConversation({ userId: userId.current, otherUserId: otherUser.id, conversationId: convId, otherUserName: otherName });
        setLoading(false);
        handleCloseModal();
      } else if (retries < maxRetries && modalShown.current) {
        retries++;
        setTimeout(getRandomUser, retryInterval);
      } else {
        setLoading(false);
        handleCloseModal();
      }
    };
    
    getRandomUser();
  };

  useEffect(() => {
    if (hubConnection.current.state === 'Disconnected')
    {
      hubConnectionStatus.current = null;
    }
    const func = startConnection();
    return func;
  }, [conversationId, hubConnection.state, startConnection]);

  useEffect(() => {
    modalShown.current = showModal;
  }, [showModal]);

  const handleExitConversation = () => {
    const newUrl = window.location.origin + window.location.pathname;
    window.history.pushState({ path: newUrl }, '', newUrl);
    leaveConversationGroup();
    setConversationId(null);
    setIsInConversation(false);
  };

  return (
    <div>
      {conversationId !== null || isInConversation ? (
        <div>
          <Conversation userId={userId} conversationId={conversationId} hubConnection={hubConnection} createNewConversation={createNewConversation} handleShowModal={handleShowModal} otherUserName={otherUserName} handleExitConversation={handleExitConversation} />
        </div>
      ) : (
        <header className="App-header">
          <div className="App-logo"></div>
          <Start handleShowModal={handleShowModal} setLoading={setLoading} userId={userId} createNewConversation={createNewConversation} age={age} setAge={setAge} sex={sex} setSex={setSex} location={location} setLocation={setLocation} />
        </header>
      )}
      <Modal show={showModal} onHide={handleCloseModal} contentClassName="modal-content-spinner" centered>
        <Modal.Body className="d-flex align-items-center justify-content-center">
          {loading ? (
            <div>
              <ChatSpinner></ChatSpinner>
              <div className="connecting">Connecting...</div>
            </div>
          ) : (
            <h5 style={{ color: 'antiquewhite' }}>Your conversation will start shortly</h5>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Chat;
