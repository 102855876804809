import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SearchForm.css';
import { Form, FormControl, InputGroup, Button } from 'react-bootstrap';

const SearchForm = ({
  onSubmit,
  placeholder,
  searchFormClassName,
  setValue,
  value,
  autoFocus,
  selectedItem
}) => {

  const [noteText, setNoteText] = useState(''); 

  const handleSubmit = () => {
    onSubmit(selectedItem, noteText);
    setNoteText('');
  };

  return (
    <div
      data-testid="SearchFormForm"
      className={`reactGiphySearchbox-searchForm-form${ searchFormClassName ? ` ${searchFormClassName}` : '' }`}>
      <FormControl
        data-testid="SearchFormInput"
        type="text"
        placeholder={placeholder}
        onChange={setValue}
        value={value}
        name="search"
        className="reactGiphySearchbox-searchForm-input"
        autoFocus={autoFocus}
        style={{ opacity: 0.8 }}
      />
      <InputGroup
        style={{ marginTop: '10px' }}>
        <FormControl
          type="text"
          placeholder="Add a note"
          onChange={(e) => setNoteText(e.target.value)}
          value={noteText}
          style={{ opacity: 0.8 }}
        />
        <Button variant="primary" type="button" onClick={handleSubmit} disabled={selectedItem === null}>
          <i className="far fa-paper-plane"></i> Send
        </Button>
      </InputGroup>
    </div>
  );
};

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  searchFormClassName: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  selectedItem: PropTypes.object
};

export default SearchForm;
