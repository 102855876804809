import * as React from 'react';
import { useRef, useEffect, Children } from 'react';
import Bricks from 'bricks.js';
import PropTypes from 'prop-types';

const MasonryLayout = ({ children, sizes }) => {
  const container = useRef(null);

  useEffect(() => {
    const bricks = Bricks({
      container: container.current,
      packed: 'data-packed',
      sizes,
      position: true,
    });

    bricks.resize(true);

    if (Children.count(children) > 0) {
      bricks.pack();
    }
  }, [children, sizes]);

  return (
    <div ref={container} data-testid="MasonryLayoutContainer">
      {children}
    </div>
  );
};

MasonryLayout.propTypes = {
  children: PropTypes.array.isRequired,
  sizes: PropTypes.array.isRequired
};

export default MasonryLayout;
