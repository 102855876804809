import React, { useState, useEffect, useCallback } from 'react';
import './Start.css';
import api from './api';
import randomBytes from 'randombytes';
import PropTypes from 'prop-types';

function Start({ age, sex, location, setAge, setSex, setLocation, handleShowModal, setLoading, userId, createNewConversation }) {
  const [locationOptions, setLocationOptions] = useState([]);
  const [ageValid, setAgeValid] = useState(true);
  const [sexValid, setSexValid] = useState(true);
  const [locationValid, setLocationValid] = useState(true);

  useEffect(() => {
    const fetchLocationOptions = async () => {
      try {
        const response = await api.get('/locations');
        setLocationOptions(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchLocationOptions();
  }, []);

  const validateAge = () => {
    if (age === '') {
      setAgeValid(false);
    } else {
      setAgeValid(true);
    }
  };

  const validateSex = () => {
    if (sex === '') {
      setSexValid(false);
    } else {
      setSexValid(true);
    }
  };

  const validateLocation = () => {
    if (location === '') {
      setLocationValid(false);
    } else {
      setLocationValid(true);
    }
  };

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    let isValid = true;
    if (age === '') {
      setAgeValid(false);
      isValid = false;
    } else {
      setAgeValid(true);
    }
  
    if (sex === '') {
      setSexValid(false);
      isValid = false;
    } else {
      setSexValid(true);
    }
  
    if (location === '') {
      setLocationValid(false);
      isValid = false;
    } else {
      setLocationValid(true);
    }
    if (!isValid) {
      return;
    }
    handleShowModal();
    setLoading(true);
    let token = getCookie('chatToken');
    let newUserId = null;
    if (!token) {
      token = generateToken();
      setCookie('chatToken', token, 30); // set cookie for 30 days
      // Call API to create new User object
      const response = await api.post('/users', {
        age,
        sex,
        location,
        token,
        isAvailable: true, // set user as available for chat
        conversations: [],
        messages: []
      });
      const newUserId = response.data.id;
      userId.current = newUserId;
    } else {
      const user = await getUserByToken(token);
      newUserId = user.id;
      userId.current = newUserId;
      // Set user as available for chat
      await api.put(`/users/${newUserId}`, {
        id: newUserId,
        isAvailable: true,
        age,
        sex,
        location,
        conversations: [],
        messages: []
      });
    }
    createNewConversation();
    
  }, [createNewConversation, setLoading, userId, age, sex, location, handleShowModal]);

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = '; expires=' + date.toUTCString();
    document.cookie = name + '=' + (value || '')  + expires + '; path=/';
  };

  const getCookie = (name) => {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i=0;i < ca.length;i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  };

  const getUserByToken = async (token) => {
    const response = await api.get(`/Users/userbytoken?token=${token}`);
    return response.data;
  };

  const generateToken = () => {
    const token = randomBytes(16).toString('hex');
    return token;
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Age:
        <span><input type="number" onBlur={validateAge} className={ageValid ? '' : 'invalid'} value={age} onChange={event => setAge(event.target.value)} /></span>
      </label>
      <label>
        Sex:
        <span><input type="text" onBlur={validateSex} className={sexValid ? '' : 'invalid'} value={sex} onChange={event => setSex(event.target.value)} maxLength="6" /></span>
      </label>
      <label>
        Location:
        <span><select value={location} onBlur={validateLocation} className={locationValid ? '' : 'invalid'} onChange={event => setLocation(event.target.value)} >
          <option value="">--Select Location--</option>
          {locationOptions.map(locationOption => (
            <option key={locationOption.value} value={locationOption.value}>
              {locationOption.label}
            </option>
          ))}
        </select></span>
      </label>
      <input type="submit" style={{ alignSelf: 'center' }} value="Start Conversation"/>
    </form>
  );
}

Start.propTypes = {
  age: PropTypes.string,
  sex: PropTypes.string,
  location: PropTypes.string,
  setAge: PropTypes.func.isRequired,
  setSex: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  userId: PropTypes.shape({
    current: PropTypes.number,
  }).isRequired,
  createNewConversation: PropTypes.func.isRequired,
};

export default Start;
