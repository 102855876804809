import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Conversation.css';
import Message from './Message';
import NewMessageForm from './NewMessageForm';
import api from './api';
import { Modal, Navbar, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Trianglify from 'trianglify';

function Conversation({ userId, conversationId, hubConnection, createNewConversation, handleShowModal, otherUserName, handleExitConversation }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [fullImage, setFullImage] = useState(null);
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const messagesEndRef = useRef(null);
  const chatRef = useRef(null);

  const flipHorizontally = (img, x, y, context) => {
    // Move to x + img's width
    context.translate(x, y);

    // ScaleX by -1; this "trick" flips horizontally
    context.scale(-1, 1);

    // Draw the img
    // No need for x,y since we've already translated
    context.drawImage(img, 0, 0);

    // Always clean up -- reset transformations to default
    context.setTransform(1, 0, 0, 1, 0, 0);
  };

  useEffect(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d', { willReadFrequently: true });
    const pattern = Trianglify({
      width: window.innerWidth,
      height: window.innerHeight,
      cellSize: 70,
      variance: 1,
      strokeWidth: 1,
      xColors: 'GnBu',
      yColors: 'match',
      seed: 'myseed' + userId.current,
    });
  
    pattern.toCanvas(canvas);
    const img = new Image();
    img.src = canvas.toDataURL();
  
    img.addEventListener('load', () => {
      context.drawImage(img, 0, 0);
  
      const croppedImageData = context.getImageData(200, 0, canvas.width - 200, canvas.height);
  
      // Flip the image horizontally
      flipHorizontally(img, img.width, 0, context);
  
      // Save the flipped image data
      const flippedImageData = context.getImageData(canvas.width - img.width, 0, img.width, img.height);
  
      // Flip the image back to the original orientation
      flipHorizontally(img, img.width, 0, context);
  
      // Resize the canvas to fit both images
      canvas.width = croppedImageData.width + (2 * flippedImageData.width);
      canvas.height = croppedImageData.height;
  
      // Draw the original and flipped images side by side
      context.putImageData(flippedImageData, 0, 0);
      context.putImageData(croppedImageData, flippedImageData.width, 0);
      context.putImageData(flippedImageData, flippedImageData.width + croppedImageData.width, 0);
      setBackgroundUrl(canvas.toDataURL());
    });
  }, [userId]);
  

  useEffect(() => {
    const handleKeyDown = (event) => {
      const scrollContainer = chatRef.current;
      const scrollStep = 50;
      const scrollMax = scrollContainer.scrollHeight - scrollContainer.clientHeight;
  
      if (event.keyCode === 38) { // up arrow key
        event.preventDefault();
        scrollContainer.scrollTop -= scrollStep;
        if (scrollContainer.scrollTop < 0) {
          scrollContainer.scrollTop = 0;
        }
      } else if (event.keyCode === 40) { // down arrow key
        event.preventDefault();
        scrollContainer.scrollTop += scrollStep;
        if (scrollContainer.scrollTop > scrollMax) {
          scrollContainer.scrollTop = scrollMax;
        }
      }
    };
  
    chatRef.current.focus();
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    // scroll to the bottom of the chat window when new messages are added
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const startConnection = useCallback(async () => {
    if (hubConnection.current.state === 'Disconnected')
    {
      await hubConnection.current.start();
    }
  }, [hubConnection]);

  const getFullImage = async (messageId) => {
    const response = await api.get(`/messages/${messageId}/image`);
    setFullImage(response.data.dataUrl);
  };      

  const handleNewMessage = useCallback((newMessage) => {
    setMessages([...messages, newMessage]);
  }, [messages]);

  useEffect(() => {
    hubConnection.current.on('ReceiveMessage', (message) => {
      handleNewMessage(message);
    });
  }, [hubConnection, handleNewMessage]);

  useEffect(() => {
    hubConnection.current.on('StartTyping', (convId, uId) => {
      if (uId !== userId.current && convId === conversationId) {
        setIsTyping(true);
      }
    });
      
    hubConnection.current.on('StopTyping', (convId, uId) => {
      if (uId !== userId.current && convId === conversationId) {
        setTimeout(() => {
          setIsTyping(false);
        }, 10);
      }
    });
  }, [conversationId, hubConnection, userId]);
      

  useEffect(() => {
    const fetchMessages = async () => {
      const response = await api.get(`/messages/${conversationId}`);
      setMessages(response.data);
    };
  
    if (conversationId) {
      fetchMessages();
      // Add conversationId to URL
      window.history.replaceState(null, null, `?conversationId=${conversationId}`);
    }
  }, [conversationId]);
  
  const handleRandomUser = useCallback(async () => {
    // update state with new conversation ID
    // and fetch messages for new conversation
    // setMessages([]);
    // setNewMessage('');
    console.log('leaving conversation group ' + conversationId);
    await hubConnection.current.invoke('LeaveConversationGroup', conversationId);
    handleShowModal();
    // Set user as available for chat
    await api.put(`/users/${userId.current}`, {
      id: userId.current,
      isAvailable: true,
      conversations: [],
      messages: []
    });
    createNewConversation();
  }, [conversationId, createNewConversation, handleShowModal, hubConnection, userId]);

  const handleStartTyping = useCallback(async () => {
    if (conversationId !== null) {
      await startConnection();
      hubConnection.current.invoke('StartTyping', conversationId, userId.current);
    }
  }, [conversationId, startConnection, userId, hubConnection]);
    
  const handleStopTyping = useCallback(async () => {
    if (conversationId !== null) {
      await startConnection();
      hubConnection.current.invoke('StopTyping', conversationId, userId.current);
    }
  }, [conversationId, startConnection, userId, hubConnection]);

  const exitConversation = useCallback(() => {
    handleExitConversation();
  }, [handleExitConversation]);

  return (
    <div className='chat-container'>
      <div id="background-canvas" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: `url(${backgroundUrl})`, backgroundRepeat: 'repeat' }}></div>
      <Navbar fixed="top" className="justify-content-between" style={{ opacity:0.8, maxHeight: '55px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
        <div className="text-center" style={{ width: '100%', marginLeft: '20px', fontWeight: 'bold', lineHeight: '1' }}>Conversation with {otherUserName}</div>
        <Button variant="outline-dark" onClick={handleRandomUser} style={{ width: '160px', minWidth: '160px', marginRight: '20px' }}>
          <i className="far fa-user"></i> Random User
        </Button>
        <Button variant="outline-danger" title="End Conversation" onClick={exitConversation}>
          <i className="fas fa-times"></i>
        </Button>
      </Navbar>
      <div className="chat-bubbles" ref={chatRef}>
        {/* Display the messages in the conversation */}
        {messages.map(message => (
          <Message key={message.id} message={message} messages={messages} userId={userId} getFullImage={getFullImage} setFullImage={setFullImage} />
        ))}
        <div ref={messagesEndRef} />
      </div>
      {isTyping && <p style={{ zIndex: '9', marginTop: '-40px' }}>Typing...</p>}
      <NewMessageForm conversationId={conversationId} userId={userId} onNewMessage={handleNewMessage} handleStartTyping={handleStartTyping} handleStopTyping={handleStopTyping} />
      <Modal show={fullImage !== null} onHide={() => setFullImage(null)} centered size="xl">
        <Modal.Dialog style={{ maxHeight: '90vh' }}>
          <Modal.Body className="d-flex align-items-center justify-content-center">
            <img src={fullImage} alt="Full-size" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>

    </div>
  );
}

Conversation.propTypes = {
  conversationId: PropTypes.number,
  otherUserName: PropTypes.string.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  userId: PropTypes.shape({
    current: PropTypes.number
  }).isRequired,
  createNewConversation: PropTypes.func.isRequired,
  hubConnection: PropTypes.shape({
    current: PropTypes.shape({
      state: PropTypes.string.isRequired,
      start: PropTypes.func.isRequired,
      on: PropTypes.func.isRequired,
      invoke: PropTypes.func.isRequired
    }).isRequired
  }).isRequired,
  handleExitConversation: PropTypes.func.isRequired
};

export default Conversation;
