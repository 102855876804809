import { useEffect, useState, useCallback } from 'react';

export default function useMedia(queries, values, defaultValue) {
  const mediaQueryLists = queries.map(q => window.matchMedia(q));

  const getValue = useCallback(() => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  }, [mediaQueryLists, defaultValue, values]);

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => {
      const newValue = getValue();
      if (newValue !== value) {
        setValue(newValue);
      }
    };

    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach(mql => mql.addListener(handler));

    // Remove listeners on cleanup
    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
  }, [getValue, mediaQueryLists, defaultValue, values, value]);

  return value;
}
