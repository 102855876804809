import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ImageItem.css';

const getUrl = (fileType) => {
  if (fileType === 'gif') {
    return 'url';
  }

  return fileType;
};

const ImageItem = ({
  backgroundColor,
  item,
  imageRenditionName,
  imageRenditionFileType,
  size,
  listItemClassName,
  onSelect,
  isSelected
}) => {

  const handleOnClick = (item) => {
    onSelect(item);
  };

  return (
    <button
      data-testid="ImageItemButton"
      type="button"
      className={`reactGiphySearchbox-imageButton${
        listItemClassName ? ` ${listItemClassName}` : ''
      }${isSelected ? ' reactGiphySearchbox-imageButton--selected' : ''}`}
      style={{
        backgroundColor,
        width: `${size}px`,
        height: `${(item.images[imageRenditionName].height * size) /
          item.images[imageRenditionName].width}px`
      }}
      onClick={() => handleOnClick(item)}
    >
      <img
        data-testid="ImageItemImage"
        width={item.images[imageRenditionName].width}
        height={item.images[imageRenditionName].height}
        alt={item.title}
        src={item.images[imageRenditionName][getUrl(imageRenditionFileType)]}
        className="reactGiphySearchbox-image"
      />
    </button>
  );
};

ImageItem.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  imageRenditionName: PropTypes.string.isRequired,
  imageRenditionFileType: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  listItemClassName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired
};

export default ImageItem;
