import React, { useState, useCallback, useRef, useEffect } from 'react';
import './Message.css';
import PropTypes from 'prop-types';
import TypeIt from 'typeit';
import moment from 'moment/moment';
import { gsap } from 'gsap/all';
import Plyr from 'plyr';
import flvjs from 'flv.js';
import 'plyr/dist/plyr.css';

function Message(props) {
  const { message, userId, getFullImage, setFullImage, messages } = props;
  const messageRef = useRef(null);
  const videoRef = useRef(null);
  const [timeAgo, setTimeAgo] = useState('');
  let tween = null;

  const handleMouseEnter = () => {
    tween = gsap.to(messageRef.current, {
      duration: 2,
      rotationY: 30,
      scale: 1.1,
      ease: 'elastic'
    });
  };

  const handleMouseLeave = () => {
    gsap.killTweensOf(messageRef.current);
    tween = null;
    gsap.to(messageRef.current, {
      duration: 0.5,
      rotationY: 0,
      scale: 1,
      ease: 'power1.inOut'
    });
  };

  useEffect(() => {
    if (messageRef.current) {
      if (messages[messages.length - 1].id === message.id) {
        if (!messageRef.current.textContent) {
          new TypeIt(messageRef.current, {
            speed: 50,
            startDelay: 500,
            waitUntilVisible: false,
            cursor: true
          }).type(message.body).go();
        }
      } else {
        messageRef.current.textContent = message.body;
      }
    }
  }, [message, messages]);

  const getTimeAgo = useCallback((timestamp) => {
    // calculate the time difference between the message time and the current time
    const messageTime = moment.utc(timestamp).local().toDate();
    const currentTime = new Date();
    const diffInSeconds = Math.round((currentTime - messageTime) / 1000);
    const sentOrReceived = message.senderId === userId.current ? 'Sent' : 'Received';

    // determine the time ago text to display
    if (diffInSeconds < 60) {
      return `${sentOrReceived} less than 1 minute ago`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      return `${sentOrReceived} ${diffInMinutes} ${diffInMinutes > 1 ? 'minutes' : 'minute'} ago`;
    } else if (diffInSeconds < 86400) {
      const diffInHours = Math.floor(diffInSeconds / 3600);
      return `${sentOrReceived} ${diffInHours} ${diffInHours > 1 ? 'hours' : 'hour'} ago`;
    } else {
      const diffInDays = Math.floor(diffInSeconds / 86400);
      return `${sentOrReceived} ${diffInDays} ${diffInDays > 1 ? 'days' : 'day'} ago`;
    }
  }, [message.senderId, userId]);

  useEffect(() => {
    setTimeAgo(getTimeAgo(message.createdAt));

    // update the time ago text every minute
    const intervalId = setInterval(() => {
      setTimeAgo(getTimeAgo(message.createdAt));
    }, 60000);

    // clear the interval on unmount
    return () => clearInterval(intervalId);
  }, [getTimeAgo, message]);

  const handleClick = useCallback(() => {
    if (message.type === 'image') {
      getFullImage(message.id);
    } else {
      setFullImage(message.imageUrl);
    }
  }, [message, getFullImage, setFullImage]);

  useEffect(() => {
    if (videoRef.current !== null)
    {
      // Create a new instance of Plyr for the video element
      const video = videoRef.current.querySelector('.plyr');

      if (message.fileExtension === 'flv') {
        if (flvjs.isSupported()) {
          const flvPlayer = flvjs.createPlayer({
            type: message.fileExtension,
            url: message.imageUrl,
          });
    
          flvPlayer.attachMediaElement(video);
          flvPlayer.load();
          flvPlayer.play();
        } else {
          console.error('FLV is not supported');
        }
      }

      const player = new Plyr(video);

      // Cleanup function to destroy the Plyr instance when the component unmounts
      return () => {
        player.destroy();
      };
    }
  }, [videoRef, message.imageUrl, message.fileExtension]);

  const handleVideoError = (event) => {
    console.log('Video error:', event.target.error);
  };
  

  if (message.type === 'image') {
    return (
      <div role="button" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} onKeyDown={(e) => { if (e.key === 'Enter') { handleClick(); }}} tabIndex={0} className={`chat-bubble3 ${message.senderId === userId.current ? 'sent' : 'received'}`}>
        <img src={message.thumbnail} ref={messageRef} alt="Message" />
        <div className="time-ago-3">{timeAgo}</div>
      </div>
    );
  } else if (message.type === 'video') {
    return (
      <div ref={videoRef} className={`chat-bubble3 ${message.senderId === userId.current ? 'sent' : 'received'}`}>
        <video className="plyr" controls onError={handleVideoError}>
          <source src={message.imageUrl} type={message.mimeType} />
        </video>
        <div className="time-ago-3">{timeAgo}</div>
      </div>
    );
  } else if (message.imageUrl) {
    return (
      <div role="button" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} onKeyDown={(e) => { if (e.key === 'Enter') { handleClick(); }}} tabIndex={0} className={`chat-bubble3 ${message.senderId === userId.current ? 'sent' : 'received'}`}>
        <img src={message.thumbnail} alt="Message" />
        <div className="inner-inner-text" ref={messageRef}>
        </div>
        <div className="time-ago-3">{timeAgo}</div>
      </div>
    );
  } else {
    return (
      <div className={`chat-bubble2 ${message.senderId === userId.current ? 'sent' : 'received'}`}>
        <div className="inner-text" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div className="inner-inner-text" ref={messageRef}>
          </div>
        </div>
        <div className="time-ago">{timeAgo}</div>
      </div>
    );
  }
}

Message.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    senderId: PropTypes.number.isRequired,
    thumbnail: PropTypes.string,
    body: PropTypes.string,
    imageUrl: PropTypes.string,
    imageContentType: PropTypes.string,
    mimeType: PropTypes.string,
    fileExtension: PropTypes.string,
    createdAt: PropTypes.string.isRequired
  }).isRequired,
  messages: PropTypes.array.isRequired,
  userId: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }).isRequired,
  getFullImage: PropTypes.func.isRequired,
  setFullImage: PropTypes.func.isRequired
};

export default Message;
