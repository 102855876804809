import React from 'react';
import PropTypes from 'prop-types';
import './Alert.css';

const Alert = ({ show, message }) => {

  return (
    show && (
      <p
        role="alert"
        data-testid="Alert"
        className="reactGiphySearchbox-message"
      >
        {message}
      </p>
    )
  );
};

Alert.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};

export default Alert;
