import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SearchForm from './SearchForm';
import ImageItem from './ImageItem';
import PoweredByGiphy from './PoweredByGiphy';
import MasonryLayout from './MasonryLayout';
import Alert from './Alert';
import Spinner from './Spinner';
import useSearchForm from '../../hooks/useSearchForm';
import useDebounce from '../../hooks/useDebounce';
import useMedia from '../../hooks/useMedia';
import useApi from '../../hooks/useApi';
import {
  getComponentWrapperWidth,
  getDefaultMasonryConfig,
  getMasonryConfigExceptLast,
  getMediaBreakpoints,
} from '../../utils/masonry';
import PropTypes from 'prop-types';
import './SearchBox.css';

const SearchBox = ({
  apiKey,
  autoFocus,
  gifListHeight,
  gifPerPage,
  imageBackgroundColor,
  imageRenditionFileType,
  imageRenditionName,
  library,
  listItemClassName,
  listWrapperClassName,
  loadingImage,
  masonryConfig,
  messageError,
  messageLoading,
  messageNoMatches,
  onSearch,
  onSelect,
  onSubmit,
  poweredByGiphy,
  poweredByGiphyImage,
  rating,
  searchFormClassName,
  searchPlaceholder,
  wrapperClassName,
}) => {
  const { query, handleInputChange } = useSearchForm();
  const debouncedQuery = useDebounce(query, 500);

  const apiEndpoint = query ? 'search' : 'trending';
  const apiUrl = useCallback((offset) =>
    `https://api.giphy.com/v1/${library}/${apiEndpoint}?api_key=${apiKey}&limit=${gifPerPage}&rating=${rating}&offset=${offset}&q=${query}`
  , [apiEndpoint, apiKey, gifPerPage, library, query, rating]);

  const [{ data, loading, error, lastPage }, fetchImages] = useApi();

  const masonryConfigMatchMedia = useMedia(
    getMediaBreakpoints(masonryConfig),
    getMasonryConfigExceptLast(masonryConfig),
    getDefaultMasonryConfig(masonryConfig),
  );

  const [selectedItem, setSelectedItem] = useState(null);

  // Fetch Giphy Api on component mount and on search query change
  const [firstRun, setFirstRun] = useState(true);
  const isFirstRun = useRef(true);
  useEffect(() => {
    fetchImages(apiUrl(0));
    onSearch(query);

    if (isFirstRun.current) {
      isFirstRun.current = false;
      setFirstRun(false);
    }
  }, [debouncedQuery, apiUrl, fetchImages, onSearch, query]);

  const handleSelect = useCallback((item) => {
    setSelectedItem(item === selectedItem ? null : item);
    onSelect(item);
  }, [selectedItem, onSelect]);

  const handleValueChange = (e) => {
    setSelectedItem(null);
    handleInputChange(e);
  };

  const handleSubmit = (item, noteText) => {
    onSubmit(item.images.fixed_width.url, item.images.hd ? item.images.hd.url : item.images.original.url, noteText);
    setSelectedItem(null);
  };

  return (
    <div
      className={`reactGiphySearchbox-componentWrapper${
        wrapperClassName ? ` ${wrapperClassName}` : ''
      }`}
      style={{ width: getComponentWrapperWidth(masonryConfigMatchMedia) }}
    >
      <SearchForm
        value={query}
        setValue={handleValueChange}
        onSubmit={handleSubmit}
        loadingData={loading}
        searchFormClassName={searchFormClassName}
        placeholder={searchPlaceholder}
        autoFocus={autoFocus}
        selectedItem={selectedItem}
      />

      <div
        className={`reactGiphySearchbox-listWrapper${
          listWrapperClassName ? ` ${listWrapperClassName}` : ''
        }`}
        style={{ height: gifListHeight }}
      >
        <Alert
          show={data.length === 0 && !loading && !error && !firstRun}
          message={messageNoMatches}
        />

        <Alert show={error} message={messageError} />

        <Spinner show={loading} message={messageLoading} image={loadingImage} />

        <InfiniteScroll
          pageStart={0}
          loadMore={page => fetchImages(apiUrl(page * gifPerPage), true)}
          hasMore={!loading && !lastPage}
          useWindow={false}
          initialLoad={false}
          loader={
            !firstRun && (
              <div key="loading">
                <Spinner
                  show={loading}
                  message={messageLoading}
                  image={loadingImage}
                />
              </div>
            )
          }
        >
          {data.length > 0 && (
            <MasonryLayout sizes={masonryConfig}>
              {data.map(item => (
                <ImageItem
                  item={item}
                  size={masonryConfigMatchMedia.imageWidth}
                  key={item.id}
                  listItemClassName={listItemClassName}
                  onSelect={handleSelect}
                  isSelected={selectedItem === item}
                  backgroundColor={imageBackgroundColor}
                  imageRenditionName={imageRenditionName}
                  imageRenditionFileType={imageRenditionFileType}
                />
              ))}
            </MasonryLayout>
          )}
        </InfiniteScroll>
      </div>
      {poweredByGiphy && <PoweredByGiphy image={poweredByGiphyImage} />}
    </div>
  );
};

SearchBox.defaultProps = {
  apiKey: 'oEQg74e2eo70gMAuMFjYVMBO4QQuGoQA',
  autoFocus: false,
  gifListHeight: '250px',
  gifPerPage: 20,
  imageBackgroundColor: '#eee',
  imageRenditionFileType: 'gif',
  imageRenditionName: 'fixed_width_downsampled',
  library: 'gifs',
  listItemClassName: '',
  listWrapperClassName: '',
  loadingImage: undefined,
  masonryConfig: [{ columns: 3, imageWidth: 120, gutter: 5 }],
  messageError: 'Oops! Something went wrong. Please, try again.',
  messageLoading: 'Loading...',
  messageNoMatches: 'No matches found.',
  onSearch: () => {},
  onSelect: () => {},
  onSubmit: () => {},
  poweredByGiphy: true,
  poweredByGiphyImage: undefined,
  rating: 'pg',
  searchFormClassName: '',
  searchPlaceholder: 'Search for GIFs',
  wrapperClassName: '',
};

SearchBox.propTypes = {
  apiKey: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  gifListHeight: PropTypes.string.isRequired,
  gifPerPage: PropTypes.number.isRequired,
  imageBackgroundColor: PropTypes.string.isRequired,
  imageRenditionFileType: PropTypes.string.isRequired,
  imageRenditionName: PropTypes.string.isRequired,
  library: PropTypes.string.isRequired,
  listItemClassName: PropTypes.string.isRequired,
  listWrapperClassName: PropTypes.string.isRequired,
  loadingImage: PropTypes.string,
  masonryConfig: PropTypes.array.isRequired,
  messageError: PropTypes.string.isRequired,
  messageLoading: PropTypes.string.isRequired,
  messageNoMatches: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  poweredByGiphy: PropTypes.bool.isRequired,
  poweredByGiphyImage: PropTypes.string,
  rating: PropTypes.string.isRequired,
  searchFormClassName: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string.isRequired
};

export default SearchBox;
