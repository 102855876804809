import React from 'react';
import SpinnerIcon from '../../assets/SpinnerIcon';
import PropTypes from 'prop-types';
import './Spinner.css';

const Spinner = ({ show, message, image }) => {

  return (
    show && (
      <div role="status" className="reactGiphySearchbox-spinnerWrapper">
        <div className="reactGiphySearchbox-spinner" data-testid="Spinner">
          {image ? <img src={image} alt="Loading icon" /> : <SpinnerIcon />}
        </div>
        <div
          className="reactGiphySearchbox-spinnerText"
          data-testid="SpinnerText"
        >
          {message}
        </div>
      </div>
    )
  );
};

Spinner.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  image: PropTypes.string
};

export default Spinner;
