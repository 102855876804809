import React from 'react';
import './ChatSpinner.css';

const ChatSpinner = () => {

  return (
    <div className="chat-spinner">
      <div className="chat-signal"></div>
    </div> 
  );
};

export default ChatSpinner;
