import React from 'react';
import PoweredByGiphyLogo from '../../assets/PoweredByGiphyLogo';
import './PoweredByGiphy.css';
import PropTypes from 'prop-types';

const PoweredByGiphy = ({ image }) => {

  return (
    <div className="reactGiphySearchbox-poweredByGiphy">
      {image ? (
        <img src={image} alt="Powered by Giphy" data-testid="PoweredByGiphy" />
      ) : (
        <PoweredByGiphyLogo />
      )}
    </div>
  );
};

PoweredByGiphy.propTypes = {
  image: PropTypes.string
};

export default PoweredByGiphy;
