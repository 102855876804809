import axios from 'axios';

const isProd = true;

const instance = axios.create({
  baseURL: isProd ? '/api' : 'https://localhost:7052/api' // replace with your desired URL
});

instance.interceptors.request.use(function (config) {
  console.log(`axios request: ${config.url}`, config);
  return config;
});

instance.interceptors.response.use(function (response) {
  console.log(`axios response: ${response.config.url}`, response);
  return response;
}, function (error) {
  console.log(`axios error: ${error.config.url}`, error);
  return Promise.reject(error);
});

export default instance;
